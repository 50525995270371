'use strict';

import "@babel/polyfill";
import { ua } from './view/_ua';
import { header } from './view/_header';
import { accordion } from './view/_accordion';
import { smooth } from './view/_smooth';
import { form } from './view/_form';
import { modal } from './view/_modal';
import { scroll } from './view/_scroll';
