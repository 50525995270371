import Swiper from 'swiper/bundle';


window.addEventListener("DOMContentLoaded", () => {
    // モーダルを取得
    const modal = document.getElementById("js-modal");
    // モーダルを開く
    const openModalBtns = document.querySelectorAll(".js-modal-open");
    // モーダルを閉じる
    const closeModalBtns = document.querySelectorAll(".js-modal-close");

    // Swiperの設定
    const swiper = new Swiper(".swiper", {
    autoHeight: true,
    loop: true,
    navigation: {
        nextEl: ".l-modal__arrow.-next",
        prevEl: ".l-modal__arrow.-prev",
    },
      spaceBetween: 30, //任意のマージン
        observer: true,
        observeParents: true,
    });

    // モーダルのボタンクリック
    openModalBtns.forEach((openModalBtn) => {
        openModalBtn.addEventListener("click", (e) => {
            e.preventDefault();
            const index = parseInt(openModalBtn.dataset.target) - 1;;
            swiper.slideTo(index, 0);
            modal.classList.add("is-active");
        });
    });

    // モーダルの閉じるボタンクリック
    closeModalBtns.forEach((closeModalBtn) => {
    closeModalBtn.addEventListener("click", () => {
        modal.classList.remove("is-active");
    });
    });
});