const _menuBtn = $('#js-hamburger')
const _header = $('#js-header')
const _navigation = $('#js-navigation')

// const _subMenuBtn = $('.has-child')

//ハンバーガーボタンを押したらナビ開閉
_menuBtn.click(function(){
	$('html').toggleClass('is-fixed')
	_menuBtn.toggleClass('is-open')
	_navigation.toggleClass('is-show')

	// bodyタグにnoFixHeaderついているときは、aboutまで移動
	if ($(window).width() <= 1024){
		if ($('body').hasClass('noFixHeader')) {
			// noFixHeaderクラスがある場合、#aboutセクションへスムーススクロール
			$('html, body').animate({
				scrollTop: $('#about').offset().top
			}, 200); 
		} 
	}
})

//メニュー以外の場所を押したらナビが閉じるように
_navigation.click(function(){
	_menuBtn.removeClass('is-open')
	_navigation.removeClass('is-show')
})

// SP ハンバーガー 
$(document).ready(function() {
	if ($('body').hasClass('top-page')){
		if ($(window).width() <= 1024) {
			$(window).scroll(function() {
				const scrollPosition = $(this).scrollTop();
				const windowHeight = $(this).height(); // 現在のウィンドウの高さ
	
				if (scrollPosition > windowHeight) {
					$('body').removeClass('noFixHeader');
				} else {
					$('body').addClass('noFixHeader');
				}
			});
		}
	}
	
});
