
$(function(){
  $('a[href^="#"]').click(function() {
  const navBtn = $('.l-navigation__header')
  var speed = 500;
  var href = $(this).attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var position = target.offset().top;
  $(document).ready(function() {
    if ($(window).width() <= 1024) {
      $(navBtn).trigger('click');
      $('html').removeClass('is-fixed')
    }
  });
  $('html').animate({scrollTop:position}, speed, 'swing');
  return false;
  });
});